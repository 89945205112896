import { API_ENDPOINT, CONVERSATION_BY_USER_PATH, CONVERSATION_WITH_MESSAGE_BY_ID_PATH, CONVERSATION_RESPONSE_MESSAGE_PATH } from "Configs/NODEJSApi";
import { getAPIWithToken, postAPIWithToken } from 'Utils/useAPI';

const getAll = async () => {
    try {
        const res = await getAPIWithToken(API_ENDPOINT + CONVERSATION_BY_USER_PATH);
        return res.data;
    } catch (error: any) {
        throw error;
    }
}

const getWithMessageByID = async (params: {conversationId: string}) => {
    try {
        const fullPath = CONVERSATION_WITH_MESSAGE_BY_ID_PATH.replace(/:conversationId/g, match => {
            if(match === ":conversationId"){
                return params.conversationId;
            }
            return "";
        });
        const res = await getAPIWithToken(API_ENDPOINT + fullPath);
        return res.data;
    } catch (error: any) {
        throw error;
    }
}

const pushMessage = async (params: object) => {
    try {
        const res = await postAPIWithToken(API_ENDPOINT + CONVERSATION_RESPONSE_MESSAGE_PATH, params);
        return res.data;
    } catch (error: any) {
        throw error;
    }
}

const ConversationRepo = {
    getAll,
    getWithMessageByID,
    pushMessage

};

export default ConversationRepo;