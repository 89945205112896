import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { NavLink } from 'react-router-dom'
import Button from 'Components/Button';
import { useTranslation } from "react-i18next";
import useSetLocalYupService from 'Utils/useSetLocalYupService';
import UserRepo from 'Repositories/UserRepo';
import { IRegisterObject } from 'DTOs/IAuth';

interface RegisterFormProps {
    setRegisterSuccess: (registerSuccess: boolean) => void
}

const RegisterForm: React.FC<RegisterFormProps> = ({ setRegisterSuccess }) => {
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState<boolean>(false);

    const [error, setError] = useState<string>("");
    const { t } = useTranslation();

    useSetLocalYupService();

    const schema = yup.object().shape({
        email: yup.string().required().email().max(50).label(t('auth.email')),
        firstName: yup.string().required().max(50).label(t('auth.firstName')),
        lastName: yup.string().required().max(50).label(t('auth.lastName')),
        password: yup.string().max(100)
            .required()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                t('auth.passwordNotMatchRegex')
            )
            .label(t('auth.password')),
        confirm_password: yup.string()
            .oneOf([yup.ref('password')])
            .required()
            .label(t('auth.confirmPassword')),

    });

    const { register: formValue, handleSubmit, formState: { errors } } = useForm<IRegisterObject>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (params: IRegisterObject) => {
        const res = await UserRepo.register(params);
        if (res.email) {
            setRegisterSuccess(true);
        } else if (res?.response?.data?.message) {
            setError(res.response.data.message);
        }
        else {
            console.log(res);
        }
    }
    const handleButtonClick = async () => {
        await handleSubmit(onSubmit)();
    };

    const togglePassword = async () => {
        setPasswordShow(!passwordShow);
    }
    const toggleConfirmPassword = async () => {
        setConfirmPasswordShow(!confirmPasswordShow);
    }
    return (
        <form>
            <h2 className="auth_title">{t("auth.registerPage")}</h2>
            <div className='form_group label_icon'>
                <span className="label_before_icon envelope_fill_icon"></span>
                <input placeholder={t("auth.enterEmail")} {...formValue("email")} />
            </div>
            <div className='form_group label_icon'>
                <span className="label_before_icon person_fill_icon"></span>
                <input placeholder={t("auth.enterFirstName")} {...formValue("firstName")} />
            </div>
            <div className='form_group label_icon'>
                <span className="label_before_icon person_fill_icon"></span>
                <input placeholder={t("auth.enterLastName")} {...formValue("lastName")} />
            </div>
            <div className='form_group label_icon toggle_pass'>
                <span className="label_before_icon lock_fill_icon"></span>
                <input type={passwordShow ? "text" : "password"} placeholder={t("auth.enterPassword")} {...formValue("password")} />
                <Button
                    onClick={togglePassword}
                    className={`label_after_icon ${passwordShow ? 'eye_slash_icon' : 'eye_icon'}`}
                    text=''
                />
            </div>
            <div className='form_group label_icon toggle_pass'>
                <span className="label_before_icon lock_icon"></span>
                <input type={confirmPasswordShow ? "text" : "password"} placeholder={t("auth.enterConfirmPassword")} {...formValue("confirm_password")} />
                <Button
                    onClick={toggleConfirmPassword}
                    className={`label_after_icon ${confirmPasswordShow ? 'eye_slash_icon' : 'eye_icon'}`}
                    text=''
                />
            </div>
            <div className='form_group label_icon'>
                <span className='error_message_form'>{errors.email?.message}</span>
                <span className='error_message_form'>{errors.firstName?.message}</span>
                <span className='error_message_form'>{errors.lastName?.message}</span>

                <span className='error_message_form'>{errors.password?.message}</span>
                <span className='error_message_form'>{errors.confirm_password?.message}</span>

                <span className='error_message_form'>{error}</span>

            </div>
            <div className='form_group label_icon txt_right'>
                <NavLink to="/login">{t('auth.backToLogin')}</NavLink>
            </div>
            <Button onClick={handleButtonClick} isLoading={true} text={t('auth.register')} />
        </form>
    )
}

export default RegisterForm;