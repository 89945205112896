import React from 'react';
import "./Footer.scss";
import { useTranslation } from "react-i18next"
const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer>
            <div className='copyright'>{t('main.copyright')}</div>
        </footer>
    );
}

export default Footer;