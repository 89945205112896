export const APP_URL = "http://localhost:2900";
export const LOGOUT_REDIRECT_URL = "/login";
export const NO_AUTH_REDIRECT_URL = "/login";
export const LOGIN_REDIRECT_URL = "/";
export const NODEJS_TOKEN = 'nodejs_token';

export const MAX_MESSAGE_LENGTH = 500;
export const MAX_DICT_WORD_LENGTH = 20;

export const LANGUAGE_STATE = 'LANGUAGE_STATE';


