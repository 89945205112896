import { useState } from 'react';
import "./PreviousConversationBar.scss"
import Button from 'Components/Button';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useConversations } from './ConversationsProvider';

const PreviousConversationBar = () => {
    const { conversations } = useConversations();
    const { id = "" } = useParams() || "";

    const [isActivePopup, setIsActivePopup] = useState<boolean>(false);
    const user = useSelector((state: any) => state.auth.userInfo);
    const { t } = useTranslation();

    const handleTogglePopup = async () => {
        setIsActivePopup(!isActivePopup);
    }
    return (
        <nav id="previous_history" className='normal_bg'>
            <Button className="previous_history_button" onClick={handleTogglePopup} text={t('main.previousConversation')} />
            <ul className={`conversations_box normal_bg ${isActivePopup ? 'active' : ''}`}>
                <Button className="close_button close_icon_button" onClick={handleTogglePopup} text="" />
                <li className="conversations_box_title">{t('main.previousConversation')}</li>
                {conversations.map((conversation) => (
                    <li key={conversation.conversationId}>
                        <NavLink
                            onClick={() => handleTogglePopup()}
                            className={`previous_history_link ${conversation.conversationId.toString() === id && 'active'}`}
                            to={`/conversation/${user.email}/${conversation.conversationId}`}
                        >
                            {conversation.title}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default PreviousConversationBar;