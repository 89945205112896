import i18n from "i18next";
import commonUS from "Assets/I18n/us/common.json";
import authUS from "Assets/I18n/us/auth.json";
import validationUS from "Assets/I18n/us/validation.json";
import layoutUS from "Assets/I18n/us/layout.json";
import mainUS from "Assets/I18n/us/main.json";
import commonVN from "Assets/I18n/vn/common.json";
import authVN from "Assets/I18n/vn/auth.json";
import validationVN from "Assets/I18n/vn/validation.json";
import layoutVN from "Assets/I18n/vn/layout.json";
import mainVN from "Assets/I18n/vn/main.json";

const resources = {
    "en-US": {
        "translation": {
            "common": commonUS,
            "auth": authUS,
            "validation": validationUS,
            "layout": layoutUS,
            "main": mainUS
        }

    },
    "vi-VN": {
        "translation": {
            "common": commonVN,
            "auth": authVN,
            "validation": validationVN,
            "layout": layoutVN,
            "main": mainVN


        }
    },
};

i18n
    .init({
        resources: resources,
        fallbackLng: "en-US",
        debug: true,
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },

        react: {
            useSuspense: false,
        },
    });

export default i18n;