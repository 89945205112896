import Button from "Components/Button";
import ConvertMessageTypeRepo from "Repositories/ConvertMessageTypeRepo";
import { useRef, useState } from "react";
import { blobToBase64 } from "Utils/useAudio";
import useLoading from "Utils/useLoading";
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import RecordRTC from "recordrtc";

interface IRecordProps {
    setMessageConvert: (message: string) => void;
}
const Record: React.FC<IRecordProps> = ({ setMessageConvert }) => {

    const [recording, setRecording] = useState<boolean>(false);
    const mediaRecorderRef = useRef<RecordRTC | null>(null);
    const handleLoading = useLoading();
    const ffmpeg = new FFmpeg();


    const handleStartRecord = async () => {
        try {
            setMessageConvert("");
            const stream = await window.navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new RecordRTC(stream, {
                type: 'audio',
                mimeType: 'audio/ogg',
                recorderType: RecordRTC.StereoAudioRecorder,
                desiredSampRate: 16000,
            });
            mediaRecorderRef.current.startRecording();
            setRecording(true);

        } catch (error: any) {
            console.error('Error accessing microphone:', error);
        }
    }

    const handleStopRecord = async () => {
        try {
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stopRecording(() => {
                    handleLoading(async () => {
                        if (mediaRecorderRef.current) {
                            const oggBlob = mediaRecorderRef.current.getBlob();
                            await ffmpeg.load();
                            ffmpeg.writeFile('input.ogg', await fetchFile(oggBlob));
                            await ffmpeg.exec(['-i', 'input.ogg', 'output.wav']);
                            const wavData = await ffmpeg.readFile('output.wav') as Uint8Array;
                            const wavBlob = new Blob([wavData.buffer], { type: 'audio/wav' });
                            const audioBase64: string = await blobToBase64(wavBlob);
                            await ConvertMessageTypeRepo.speechToText({ audioBase64: audioBase64 }).then(res => {
                                if (res.DisplayText) {
                                    setMessageConvert(res.DisplayText);
                                }
                            });
                        }
                    })
                });
                setRecording(false);
            }
        } catch (error: any) {
            console.error('Error accessing microphone:', error);
        }
    }

    return (
        <Button
            className={!recording ? 'micro_icon' : 'stop_icon'}
            onClick={!recording ? handleStartRecord : handleStopRecord}
            text=''
        />
    );
}
export default Record;