import React from 'react';
import LoginForm from "./LoginForm";
import logo from "Assets/Images/signin-image.jpg";
import { useTranslation } from "react-i18next";

const Login = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className='auth_form'>
                <LoginForm />
            </div>
            <div className='auth_logo left_side'>
                <img src={logo} alt={t('main.logo')} />
            </div>
        </>
    );
}

export default Login;