import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import rootSaga from 'ReduxDir/Sagas';
import rootReducer from 'ReduxDir/Reducers';

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => { return getDefaultMiddleware().concat(sagaMiddleware); }
});

sagaMiddleware.run(rootSaga);

export default store;