import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { NavLink } from 'react-router-dom'
import Button from 'Components/Button';
import { useTranslation } from "react-i18next";
import useSetLocalYupService from 'Utils/useSetLocalYupService';
import { authRequest } from "ReduxDir/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { ILoginObject } from 'DTOs/IAuth';

const LoginForm = () => {
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const loginError = useSelector((state: any) => state.auth.error);
    const [error, setError] = useState<string>("");
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        setError(loginError);
    }, [loginError]);

    useSetLocalYupService();

    const schema = yup.object().shape({
        email: yup.string().max(50).email().required().label(t('auth.email')),
        password: yup.string().required().min(8).max(100).label(t('auth.password'))
    });

    const { register: formValue, handleSubmit, formState: { errors } } = useForm<ILoginObject>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (params: ILoginObject) => {
        dispatch(authRequest(params));
    }
    const handleButtonClick = async () => {
        await handleSubmit(onSubmit)();
    };
    const togglePassword = async () => {
        setPasswordShow(!passwordShow);
    }
    return (
        <form>
            <h2 className="auth_title">{t("auth.loginPage")}</h2>
            <div className='form_group label_icon'>
                <span className="label_before_icon person_fill_icon"></span>
                <input placeholder={t("auth.enterUserName")} {...formValue("email")} />
            </div>
            <div className='form_group label_icon toggle_pass'>
                <span className="label_before_icon lock_fill_icon"></span>
                <input type={passwordShow ? "text" : "password"} placeholder={t("auth.enterPassword")} {...formValue("password")} />
                <Button
                    onClick={togglePassword}
                    className={`label_after_icon ${passwordShow ? 'eye_slash_icon' : 'eye_icon'}`}
                    text=''
                />
            </div>
            <div className='form_group'>
                <span className='error_message_form'>{errors.email?.message}</span>
                <span className='error_message_form'>{errors.password?.message}</span>
                <span className='error_message_form'>{error}</span>
            </div>
            <div className='form_group txt_right'>
                {t('auth.doNotHaveAnAccount')} <NavLink to="/register">{t('auth.register')}</NavLink>
            </div>
            <Button onClick={handleButtonClick} text={t('auth.login')} />
        </form>
    )
}

export default LoginForm;