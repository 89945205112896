import { FULL_LANGUAGE, LEARN_LANGUAGE, LOCAL_LANGUAGE } from 'ReduxDir/Actions/LanguageActions';
import { IAction, ILanguageState } from 'ReduxDir/types';

const initialState: ILanguageState = {
    learnLanguage: {
        code: 'en',
        name: 'EngLish'
    },
    localLanguage: {
        code: 'vi',
        name: 'Vietnam'
    }
}

const languageReducer = (state: ILanguageState = initialState, action: IAction): ILanguageState => {
    switch (action.type) {
        case LOCAL_LANGUAGE:
            return {
                ...state,
                localLanguage: action.payload,
            };
        case LEARN_LANGUAGE:
            return {
                ...state,
                learnLanguage: action.payload,
            };
        case FULL_LANGUAGE:
            return action.payload;
        default:
            return state;
    }
}

export default languageReducer;