import './MainMenu.scss'
import logo from "Assets/Images/logo.svg";
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authReset } from 'ReduxDir/Actions/AuthActions';
import Button from 'Components/Button';
import Dictionary from '../Dictionary';

const MainMenu = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleLogoutButton = async () => {
        dispatch(authReset());
    }
    return (
        <nav id='main_menu' className='light_bg'>
            <NavLink className='logo light_bg' to="/login">
                <img src={logo} alt={t('main.logo')} />
            </NavLink>
            <div className='profile'>
                <Button className='logout_icon' onClick={handleLogoutButton} isLoading={true} text="" />
            </div>
        </nav>
    );
}

export default MainMenu;