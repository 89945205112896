import { combineReducers } from 'redux';
import authReducer from "./Auth/authReducer";
import loadingReducer from './Util/LoadingReducer';
import initAppReducer from './App/initAppReducer';
import languageReducer from './Util/LanguageReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    init: initAppReducer,
    lang: languageReducer

});

export default rootReducer;
