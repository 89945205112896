import { API_ENDPOINT, CONVERT_SPEECH_TO_TEXT_PATH, CONVERT_TEXT_TO_SPEECH_PATH } from "Configs/NODEJSApi";
import { ISpeechToTextRequest, ITextToSpeechRequest } from "DTOs/IConverMessageType";
import { postAPIWithToken } from 'Utils/useAPI';


const speechToText = async (params: ISpeechToTextRequest) => {
    try {
        const res = await postAPIWithToken(API_ENDPOINT + CONVERT_SPEECH_TO_TEXT_PATH, params);

        return res.data;
    } catch (error: any) {
        throw error;
    }
}

const textToSpeech = async (params: ITextToSpeechRequest) => {
    try {
        const res = await postAPIWithToken(API_ENDPOINT + CONVERT_TEXT_TO_SPEECH_PATH, params);
        return res.data;
    } catch (error: any) {
        throw error;
    }
}

const ConvertMessageTypeRepo = {
    speechToText,
    textToSpeech
};

export default ConvertMessageTypeRepo;