import { START_INIT_APP, FINISH_INIT_APP } from 'ReduxDir/Actions/InitAppActions';
import { IAction, ILoadingState } from 'ReduxDir/types';

const initialState: ILoadingState = {
    isLoading: true,
}

const initAppReducer = (state: ILoadingState = initialState, action: IAction): ILoadingState => {
    switch (action.type) {
        case START_INIT_APP:
            return {
                ...state,
                isLoading: true,
            };
        case FINISH_INIT_APP:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default initAppReducer;