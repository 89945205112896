
import React, { useState } from 'react';
import Button from 'Components/Button';
import "./Dictionary.scss";
import DictionaryPopup from './DictionaryPopup';

const Dictionary = () => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const handleToggleDictionaryButton = async () => {
        setIsActive(!isActive);
    }
    return (
        <>
            <div className="dictionary">
                <Button onClick={handleToggleDictionaryButton} className="search_icon" text="" />
            </div>
            {isActive && <DictionaryPopup handleToggleDictionaryButton={handleToggleDictionaryButton} />}
        </>

    );
}
export default Dictionary;