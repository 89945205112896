import { NODEJS_TOKEN } from 'Configs/App';
import { IAccessInfo } from 'DTOs/IAuth';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import { authSuccess, loginFailure, loginSuccess } from 'ReduxDir/Actions/AuthActions';
import { finishInitApp, START_INIT_APP } from "ReduxDir/Actions/InitAppActions";
import UserRepo from 'Repositories/UserRepo';

export function* initAppSaga(): Generator<any, void, any> {
    try {
        const tokenString = localStorage.getItem(NODEJS_TOKEN);

        if (!tokenString) {
            yield put(loginFailure());
        } else {
            const tokenInfo: IAccessInfo = JSON.parse(tokenString);
            yield put(authSuccess(tokenInfo));
            const [userInfo] = yield all([
                call(UserRepo.getProfile),
            ]);
            yield all([
                put(loginSuccess(userInfo)),
            ]);
        }
    } catch (error: any) {
        console.error("appSaga :" + error);
        localStorage.removeItem(NODEJS_TOKEN);
        yield put(loginFailure());

    }
    yield put(finishInitApp());
}

export function* watchInitApp() {
    yield takeLatest(START_INIT_APP, initAppSaga);
}