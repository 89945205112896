import { all } from 'redux-saga/effects';
import { watchAuth } from './authSaga';
import { watchInitApp } from './InitAppSaga';
import { watchLogout } from './logoutSaga';
import { watchRefreshToken, watchRefreshTokenScheduler } from './refreshTokenSaga';
import { watchInitLanguageSaga } from './initLanguageSaga';
import { watchLocalLanguage } from './setLocalLanguageSaga';

function* rootSaga() {
    yield all([
        watchAuth(),
        watchInitApp(),
        watchLogout(),
        watchRefreshToken(),
        watchRefreshTokenScheduler(),
        watchInitLanguageSaga(),
        watchLocalLanguage()
    ]);
}

export default rootSaga;