import React, { useEffect } from 'react';
// import "Assets/Css/Common.scss"
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { privateRoutes, authRoutes } from "Configs/Routes";
import AuthRoute from 'Containers/Layout/Routes/AuthRoute';
import PrivateRoute from 'Containers/Layout/Routes/PrivateRoute';
import Loading from 'Components/Loading';
import { startInitApp } from 'ReduxDir/Actions/InitAppActions';
import { useTranslation } from "react-i18next";

function App() {
  const isLoadingApp = useSelector((state: any) => state.init.isLoading);
  const isLoading = useSelector((state: any) => state.loading.isLoading);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startInitApp());
  }, [dispatch]);

  useEffect(() => {
    document.title = t('common.appTitle');
  }, [t]);

  if (isLoadingApp) {
    return <Loading />;
  }

  return (
    <>
      <Routes>
        {authRoutes.map(
          (route, index) => <Route {...route} element={<AuthRoute component={route.component} />} key={index} />
        )}
        {privateRoutes.map(
          (route, index) => <Route {...route} element={<PrivateRoute component={route.component} />} key={index} />
        )}
      </Routes>
      {isLoading && <Loading />}
    </>
  );
}

export default App;
