import React  from 'react';
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";

const RegisterSuccess = () => {
    const { t } = useTranslation();

    return (
        <form>
            <h4 className="auth_title">{t("auth.registerPage")}</h4>
            <div className='form_group'>
                <span className='info_message_form'>{t('auth.registerSuccess')}</span>
            </div>
            <div className='form_group txt_right'>
                <NavLink to="/login">{t('auth.backToLogin')} </NavLink>
            </div>
        </form>
    )
}

export default RegisterSuccess;