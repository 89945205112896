import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthSkeleton from "Containers/Auth/AuthSkeleton";
import { LOGIN_REDIRECT_URL } from 'Configs/App';
import { useSelector } from 'react-redux';

interface IAuthRouteProps {
    component: React.ComponentType<any>
}

const AuthRoute: React.FC<IAuthRouteProps> = ({ component: Component, ...rest }) => {
    const isLoggedIn = useSelector((state: any) => state.auth.isLogged);

    if (isLoggedIn) {
        return (
            <Navigate to={LOGIN_REDIRECT_URL} />
        );
    }
    else {
        return (
            <AuthSkeleton>
                <Component {...rest} />
            </AuthSkeleton>
        )
    }
}

export default AuthRoute;