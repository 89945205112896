const API_ENDPOINT_LIST = {
    development: "http://localhost:3000",
    test: "http://localhost:3000",
    production: "https://bk.funcipher.com"
}

export const API_ENDPOINT:string = API_ENDPOINT_LIST[process.env.NODE_ENV || 'development'];

// export const API_VERSION = "v1";

export const REGISTER_PATH = "/user/register";
export const LOGIN_PATH = "/user/login";
export const REFRESH_TOKEN_PATH = "/user/refresh-token";

export const USER_PROFILE_PATH = "/user/profile";

export const CONVERSATION_BY_USER_PATH = "/conversations";
export const CONVERSATION_WITH_MESSAGE_BY_ID_PATH = "/conversations/:conversationId";
export const CONVERSATION_RESPONSE_MESSAGE_PATH = "/message/push";

export const CONVERT_SPEECH_TO_TEXT_PATH = "/azure-speech/speech-to-text";
export const CONVERT_TEXT_TO_SPEECH_PATH = "/azure-speech/text-to-speech";

export const TRANSLATE_ENG_TO_VI_PATH = "/azure-speech/translator";
export const DICTIONARY_ENG_TO_VI_PATH = "/azure-speech/look-up-dictionary";
export const SUPPORTED_LANGUAGES_PATH = "/azure-speech/languages-supported";








