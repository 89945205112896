import React from 'react';
import "./Main.scss";
import logo from "Assets/Images/logo.svg";
import SendMessageForm from '../Conversation/SendMessageForm';
import { useTranslation } from "react-i18next";

const Main = () => {
    const { t } = useTranslation();
    return (
        <>
            <section id="new_conversation">
                <div className="greeting_box">
                    <img className="greeting_logo" src={logo} alt={t('main.logo')} />
                    <p className="greeting_message">{t('common.greeting')}</p>

                </div>
            </section>
            <SendMessageForm contentMessages={[]} />
        </>
    );
}

export default Main;