import { AUTH_REQUEST, AUTH_FAILURE, AUTH_SUCCESS, LOGIN_FAILURE, LOGIN_SUCCESS, AUTH_RESET, AUTH_TOKEN_REFRESH } from 'ReduxDir/Actions/AuthActions';
import { IAction, IAuthState } from 'ReduxDir/types';

const initialState: IAuthState = {
    userInfo: null,
    tokenInfo: null,
    error: null,
    isLogged: false

}

const authReducer = (state: IAuthState = initialState, action: IAction): IAuthState => {
    switch (action.type) {
        case AUTH_REQUEST:
            return initialState;
        case AUTH_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case AUTH_SUCCESS:
            return {
                ...state,
                tokenInfo: action.payload,
            };
        case LOGIN_FAILURE:
            return initialState;
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLogged: true,
                userInfo: action.payload,
            };
        case AUTH_RESET:
            return initialState;
        case AUTH_TOKEN_REFRESH:
            return initialState;            
        default:
            return state;
    }
}

export default authReducer;