import { NODEJS_TOKEN } from 'Configs/App';
import { IUser } from 'DTOs/IAuth';
import { select, takeLatest, call, put, delay } from 'redux-saga/effects';
import { AUTH_TOKEN_REFRESH, authSuccess, loginFailure } from 'ReduxDir/Actions/AuthActions';
import UserRepo from 'Repositories/UserRepo';


function* refreshTokenSaga(): Generator<any, void, any> {
    try {
        const userInfo: IUser = yield select(state => state.auth.userInfo);
        if (userInfo) {
            const res = yield call(UserRepo.refreshToken);
            if (res.accessToken) {
                localStorage.setItem(NODEJS_TOKEN, JSON.stringify({access_token: res.accessToken}));
                yield put(authSuccess(res));
            }
        }
    } catch (error: any) {
        console.error("appSaga :" + error);
        localStorage.removeItem(NODEJS_TOKEN);
        yield put(loginFailure());
    }
}

export function* watchRefreshToken() {
    yield takeLatest(AUTH_TOKEN_REFRESH, refreshTokenSaga);
}

function* refreshTokenScheduler() {
    while (true) {
        yield call(refreshTokenSaga);
        yield delay(20 * 60 * 1000);
    }
}

export function* watchRefreshTokenScheduler() {
    yield call(refreshTokenScheduler);
}