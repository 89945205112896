import { IMessage } from 'DTOs/IConversation';
import ConversationRepo from 'Repositories/ConversationRepo';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import './Conversation.scss';
import SendMessageForm from './SendMessageForm';
import { useSelector } from 'react-redux';
import Button from 'Components/Button';
import TranslateRepo from 'Repositories/TranslateRepo';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import Speech from 'Components/Audio/Speech';
import { ITranslateRequest } from 'DTOs/ITranslate';

interface ITranslateState {
    isActive: boolean,
    messageID: number,
    message: string
}

const initTranslate: ITranslateState = {
    isActive: false,
    messageID: -1,
    message: ""
}

const Conversation = () => {
    const { email = "", id = "" } = useParams() || "";
    const [contentMessages, setContentMessages] = useState<IMessage[]>([]);
    const [translateState, setTranslateState] = useState<ITranslateState>(initTranslate);

    const conversationBoxRef = useRef<HTMLDivElement | null>(null);
    const messageContentWordRef = useRef<HTMLDivElement | null>(null);

    const user = useSelector((state: any) => state.auth.userInfo);
    const lang = useSelector((state: any) => state.lang);

    const { t } = useTranslation();

    useEffect(() => {
        const fetchConversation = async () => {
            try {
                const res = await ConversationRepo.getWithMessageByID({ conversationId: id });
                setContentMessages(res.content);
            }
            catch (error) {
                console.error("Failed to fetch conversations", error);
            }
        }
        fetchConversation();
    }, [email, id]);

    useEffect(() => {
        const container = conversationBoxRef.current;
        if (container) {
            container.scrollTop = container.scrollHeight;

        }
    }, [contentMessages]);

    useEffect(() => {
        const handleDisableTranslate = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.raw_message_content')) {
                setTranslateState(initTranslate);
            }
        }
        document.body.addEventListener('click', handleDisableTranslate);
    }, []);

    const handleTranslateSentence = async (ID: number, rawMessage: string) => {
        const params : ITranslateRequest = {
            content: rawMessage,
            fromLanguage: lang.learnLanguage.code || 'en',
            toLanguage: lang.localLanguage.code || 'vi'
        }
        const res = await TranslateRepo.translateContext(params);
        if (res.text) {
            setTranslateState({
                isActive: true,
                messageID: ID,
                message: res.text
            });
        }
    }

    if (user.email !== email) {
        return <Navigate to={"/"} />
    }
    return (
        <>
            <NavLink className='new_conversation' to="/" >{t('main.newChat')}</NavLink>
            <div ref={conversationBoxRef} id="conversation_box">
                {contentMessages.map(contentMessage => (
                    <div key={contentMessage.messageId} className={`message_box ${contentMessage.actor === 'bot' ? 'left_message' : 'right_message'}`} >
                        <span ref={messageContentWordRef} className="message_content">
                            <Button
                                className="raw_message_content normal_color"
                                onClick={() => handleTranslateSentence(contentMessage.messageId, contentMessage.content)} text={contentMessage.content}
                            />
                            {
                                translateState.isActive &&
                                translateState.messageID === contentMessage.messageId &&
                                <span className='message_content_translate normal_bg'>{translateState.message}</span>
                            }
                        </span>
                        <div className="message_speech">
                            <Speech message={contentMessage.content} />
                        </div>
                    </div>
                ))}
            </div>
            <SendMessageForm contentMessages={contentMessages} setContentMessages={setContentMessages} conversationId={id}/>
        </>
    )
}

export default Conversation;
