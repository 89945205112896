import { LANGUAGE_STATE } from 'Configs/App';
import { select, takeLatest } from 'redux-saga/effects';
import { LOCAL_LANGUAGE } from 'ReduxDir/Actions/LanguageActions';
import { IAction } from 'ReduxDir/types';

function* setLocalLanguageSaga(action: IAction): Generator<any, void, any> {
    try {
        const languageState = yield select(state => state.lang);
        localStorage.setItem(LANGUAGE_STATE, JSON.stringify(languageState));
    } catch (error: any) {
        throw error;
    }
}

export function* watchLocalLanguage() {
    yield takeLatest(LOCAL_LANGUAGE, setLocalLanguageSaga);
}