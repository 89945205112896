import React, { ReactNode } from 'react';
import './AppSkeleton.scss'
import MainMenu from '../MainMenu';
import PreviousConversationBar from '../PreviousConversationBar';
import Footer from '../Footer';
import { ConversationsProvider } from '../PreviousConversationBar/ConversationsProvider';
import Dictionary from '../Dictionary';
import LocalLanguage from '../LocalLanguage';

interface IAuthSkeletonProps {
    children: ReactNode;
}

const AppSkeleton: React.FC<IAuthSkeletonProps> = ({ children }) => {
    return (
        <ConversationsProvider>
            <MainMenu />
            <PreviousConversationBar />
            <div className='dictionary_box'>
                <LocalLanguage />
                <Dictionary />
            </div>
            <div className='content light_bg'>
                {children}
            </div>
            <Footer />
        </ConversationsProvider>
    );
}

export default AppSkeleton;