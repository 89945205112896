import React, { useState } from 'react';
import RegisterForm from './RegisterForm';
import RegisterSuccess from './RegisterSuccess';
import logo from "Assets/Images/signup-image.jpg";
import { useTranslation } from "react-i18next";


const RegisterScreen = () => {
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const { t } = useTranslation();
    return (
        <>
            <div className='auth_form'>
                {registerSuccess ? <RegisterSuccess /> : <RegisterForm setRegisterSuccess={setRegisterSuccess} />}
            </div>
            <div className='auth_logo right_side'>
                <img src={logo} alt={t('main.logo')} />
            </div>
        </>
    );
}
export default RegisterScreen;