import React, { useEffect, useState, createContext, useContext, useMemo } from "react";
import { IConversation } from "DTOs/IConversation";
import ConversationRepo from "Repositories/ConversationRepo";


interface ConversationsContextProps {
    conversations: IConversation[];
    setConversations: React.Dispatch<React.SetStateAction<IConversation[]>>;
    fetchConversations: () => Promise<void>;
}
interface ConversationsProviderProps {
    children: React.ReactNode;
}

const ConversationContext = createContext<ConversationsContextProps | undefined>(undefined);

export const ConversationsProvider: React.FC<ConversationsProviderProps> = ({ children }) => {
    const [conversations, setConversations] = useState<IConversation[]>([]);

    const fetchConversations = async () => {
        try {
            const res = await ConversationRepo.getAll();
            setConversations(res);
        }
        catch (error) {
            console.error("Failed to fetch conversations", error);
        }
    }
    useEffect(() => {
        fetchConversations();
    }, []);

    const contextValue = useMemo(() => (
        { conversations, setConversations, fetchConversations }),
        [conversations, setConversations, fetchConversations]
    );

    return (
        <ConversationContext.Provider value={contextValue}>
            {children}
        </ConversationContext.Provider>
    );
}

export const useConversations = () => {
    const context = useContext(ConversationContext);
    if (!context) {
        throw new Error('useConversations must be used within a ConversationsProvider');
    }
    return context;
}