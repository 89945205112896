import { API_ENDPOINT, TRANSLATE_ENG_TO_VI_PATH, DICTIONARY_ENG_TO_VI_PATH, SUPPORTED_LANGUAGES_PATH } from "Configs/NODEJSApi";
import { ITranslateRequest, ISupportedLanguages } from "DTOs/ITranslate";
import { getAPIWithToken, postAPIWithToken } from 'Utils/useAPI';

const getSupportedLanguages = async () => {
    try {
        const res = await getAPIWithToken(API_ENDPOINT + SUPPORTED_LANGUAGES_PATH);
        const commonKey = Object.keys(res.data.dictionary).filter(key => res.data.translation[key]);

        const commonElements: ISupportedLanguages = commonKey.reduce((acc: ISupportedLanguages, key) => {
            acc[key] = res.data.dictionary[key];
            return acc;
        }, {});
        return commonElements;

    } catch (error: any) {
        throw error;
    }
}

const translateWord = async (params: ITranslateRequest) => {
    try {
        const res = await postAPIWithToken(API_ENDPOINT + DICTIONARY_ENG_TO_VI_PATH, params);
        return res.data[0].translations;
    } catch (error: any) {
        throw error;
    }
}

const translateContext = async (params: ITranslateRequest) => {
    try {
        const res = await postAPIWithToken(API_ENDPOINT + TRANSLATE_ENG_TO_VI_PATH, params);
        return res.data[0].translations[0];
    } catch (error: any) {
        throw error;
    }
}

const TranslateRepo = {
    translateWord,
    translateContext,
    getSupportedLanguages
};

export default TranslateRepo;