import Login from "./Auth/Login";
import Register from "./Auth/Register";

import Main from "./Content/Main";
import Conversation from "./Content/Conversation";

const components = {
    Login,
    Register,
    Main,
    Conversation
};

export default components