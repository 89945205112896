import { LANGUAGE_STATE } from 'Configs/App';
import { put } from 'redux-saga/effects';
import { setFullLanguage } from 'ReduxDir/Actions/LanguageActions';

function* initLanguageSaga(): Generator<any, void, any> {
    try {
        const languageStateString = localStorage.getItem(LANGUAGE_STATE);
        if(languageStateString){
            const languageState = JSON.parse(languageStateString);
            yield put(setFullLanguage(languageState));
        }
    } catch (error: any) {
        throw error;
    }
}

export function* watchInitLanguageSaga() {
    yield initLanguageSaga();
}