import { ILanguageState, ILearnLanguage, ILocalLanguage } from "ReduxDir/types";

export const LOCAL_LANGUAGE = 'LOCAL_LANGUAGE';
export const LEARN_LANGUAGE = 'LEARN_LANGUAGE';
export const FULL_LANGUAGE = 'FULL_LANGUAGE';


export const setLocalLanguage = (localLanguage: ILocalLanguage) => ({
    type: LOCAL_LANGUAGE,
    payload: localLanguage,
});

export const setLearnLanguage = (learnLanguage: ILearnLanguage) => ({
    type: LOCAL_LANGUAGE,
    payload: learnLanguage,
});

export const setFullLanguage = (languageState: ILanguageState) => ({
    type: FULL_LANGUAGE,
    payload: languageState,
});