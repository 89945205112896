import { API_ENDPOINT, REGISTER_PATH, LOGIN_PATH, REFRESH_TOKEN_PATH, USER_PROFILE_PATH } from "Configs/NODEJSApi";
import { getAPIWithToken, postAPI, postAPIWithToken } from 'Utils/useAPI';


const register = async (params: object) => {
    try {
        const res = await postAPI(API_ENDPOINT + REGISTER_PATH, params);
        return res.data;
    } catch (error: any) {
        return error;
    }
}

const auth = async (params: object) => {
    try {
        const res = await postAPI(API_ENDPOINT + LOGIN_PATH, params);
        return res.data;
    } catch (error: any) {
        throw error;
    }
}

const refreshToken = async () => {
    try {
        const res = await postAPIWithToken(API_ENDPOINT + REFRESH_TOKEN_PATH);
        return res.data;
    } catch (error: any) {
        throw error;
    }
}


const getProfile = async () => {
    try {
        const res = await getAPIWithToken(API_ENDPOINT + USER_PROFILE_PATH);
        return res.data;
    } catch (error: any) {
        throw error;
    }
}

const UserRepo = {
    register,
    auth,
    getProfile,
    refreshToken
};

export default UserRepo;