import Button from "Components/Button";
import ConvertMessageTypeRepo from "Repositories/ConvertMessageTypeRepo";
import { base64ToBlob } from "Utils/useAudio";
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { useState } from "react";
import "./Speech.scss";

interface ISpeechProps {
    message: string;
}

const Speech: React.FC<ISpeechProps> = ({ message }) => {
    const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
    const ffmpeg = new FFmpeg();
    const handleSpeakSentence = async (message: string) => {
        setIsSpeaking(true);
        const res = await ConvertMessageTypeRepo.textToSpeech({ text: message });
        if (res.base64Audio) {
            const audioBlob = await base64ToBlob(res.base64Audio);
            await ffmpeg.load();
            ffmpeg.writeFile('input.wav', await fetchFile(audioBlob));
            await ffmpeg.exec(['-i', 'input.wav', 'output.mp3']);
            const mp3Data = await ffmpeg.readFile('output.mp3') as Uint8Array;
            const mp3Blob = new Blob([mp3Data.buffer], { type: 'audio/mp3' });
            const audioUrl = URL.createObjectURL(mp3Blob);
            const audioElement = new Audio(audioUrl);
            audioElement.play();
        } else {
            console.log("handleSpeakSentence: ");
            console.log(res);
        }
        setIsSpeaking(false);
    }

    return (
        < Button
            className={isSpeaking ? "arrow_repeat_icon" : "speak_icon"}
            onClick={() => handleSpeakSentence(message)}
            text=""
            disabled={isSpeaking}
        />
    );
}
export default Speech;