import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';


const useSetLocalYupService = () => {

    const { t } = useTranslation();

    setLocale({
        mixed: {
            required: ({ path }) => t('validation.required', { field: path }),
            oneOf: ({ path }) => t('validation.oneOf', { field: path }),
        },
        string: {
            email: ({ path }) => t('validation.email', { field: path }),
            min: ({ path, min }) => t('validation.string_min', { field: path, min }),
            max: ({ path, max }) => t('validation.string_max', { field: path, max }),

            
        },
    });
}

export default useSetLocalYupService;