import Components from "Containers";
export const authRoutes = [
    {
        key: "SIGN_IN",
        component: Components.Login,
        exact: true,
        path: "/login",
        title: "SignIn Page",
    },
    {
        key: "REGISTER_PAGE",
        component: Components.Register,
        exact: true,
        path: "/register",
        title: "Register",
    },
];
export const privateRoutes = [
    {
        key: "MAIN_PAGE",
        component: Components.Main,
        exact: true,
        path: "/",
        title: "Main Page",
    },
    {
        key: "CONVERSATION",
        component: Components.Conversation,
        exact: true,
        path: "/conversation/:email/:id",
        title: "Conversation",
    },
];
