
import React, { useEffect, useState } from 'react';
import "./SendMessageForm.scss";
import Button from 'Components/Button';
import { IMessage, ISendMessageRequest } from 'DTOs/IConversation';
import ConversationRepo from 'Repositories/ConversationRepo';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import useSetLocalYupService from 'Utils/useSetLocalYupService';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MAX_MESSAGE_LENGTH } from "Configs/App"
import Record from 'Components/Audio/Record';
import { useSelector } from 'react-redux';
import { useConversations } from 'Containers/Layout/PreviousConversationBar/ConversationsProvider';

interface ISendMessageFormProps {
    contentMessages: IMessage[];
    setContentMessages?: (content: IMessage[]) => void;
    conversationId?: string;
}

const SendMessageForm: React.FC<ISendMessageFormProps> = ({ contentMessages, setContentMessages, conversationId }) => {
    const { fetchConversations } = useConversations();
    const [messageConvert, setMessageConvert] = useState<string | null>(null);
    const { t } = useTranslation();
    const navigator = useNavigate();
    const user = useSelector((state: any) => state.auth.userInfo);


    useSetLocalYupService();
    const schema = yup.object().shape({
        content: yup.string().required().max(50).label(t('main.message')),
        conversationId: yup.string().label(t('main.conversation'))
    });

    const { register: formValue, handleSubmit, watch, setValue } = useForm<ISendMessageRequest>({
        resolver: yupResolver(schema),
    });

    const messageValue = watch('content');
    useEffect(() => {
        if (messageValue && messageValue.length > MAX_MESSAGE_LENGTH) {
            setValue('content', messageValue.slice(0, MAX_MESSAGE_LENGTH));
        }
    }, [messageValue, setValue]);

    useEffect(() => {
        if (messageConvert) {
            setValue('content', messageConvert);
        }
    }, [messageConvert, setValue]);


    const onSubmit = async (params: ISendMessageRequest) => {
        params =  JSON.parse(JSON.stringify(params));

        if (conversationId) {
            if (setContentMessages) {
                const newMessage: IMessage = {
                    messageId: -1,
                    content: params.content,
                    actor: 'user',
                    userId: 'null',
                    createdDate: 'null'
                }
                setContentMessages([...contentMessages, newMessage]);
            }
        }
        setValue('content', "");

        if (conversationId) {
            params.conversationId = conversationId;
        }
        const res = await ConversationRepo.pushMessage(params);
        if (conversationId) {
            if (setContentMessages) {
                setContentMessages(res.content);
            } else {
                console.log("Error SendMessageForm: ")
                console.log(res)
            }
        } else {
            fetchConversations();
            navigator("/conversation/" + user.email + "/" + res.conversationId);
        }
    }

    const handleButtonClick = async () => {
        await handleSubmit(onSubmit)();
    };

    return (
        <section id="message_container">
            <form className="message_box">
                <div className="message_group">
                    <textarea {...formValue("content")}  className="normal_bg"></textarea>
                </div>
                <Record setMessageConvert={setMessageConvert} />
                <Button className='send_icon' onClick={handleButtonClick} isLoading={true} text='' />
            </form>
        </section>
    );
}
export default SendMessageForm;