import { ISupportedLanguage, ISupportedLanguages } from "DTOs/ITranslate";
import TranslateRepo from "Repositories/TranslateRepo";
import { useEffect, useState } from "react";
import "./LocalLanguage.scss";
import { useDispatch, useSelector } from "react-redux";
import { setLocalLanguage } from "ReduxDir/Actions/LanguageActions";


const LocalLanguage = () => {
    const [selectedValue, setSelectedValue] = useState<string>('vi');
    const [supportedLanguages, setSupportedLanguages] = useState<ISupportedLanguages>({});
    const lang = useSelector((state: any) => state.lang);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchSupportedLanguages = async () => {
            const res = await TranslateRepo.getSupportedLanguages();
            setSupportedLanguages(res)
        }
        fetchSupportedLanguages();
    }, []);

    useEffect(() => {
        setSelectedValue(lang.localLanguage.code);
    }, [lang.localLanguage.code]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedKey = event.target.value;
        const selectedValue = supportedLanguages[selectedKey].name;
        dispatch(setLocalLanguage({ code: selectedKey, name: selectedValue }));
    };

    return (
        <select value={selectedValue} onChange={handleChange}>
            {
                Object.keys(supportedLanguages).map((key) => {
                    const language: ISupportedLanguage = supportedLanguages[key];
                    return (
                        <option key={key} value={key}>
                            {language.name}
                        </option>
                    );
                })
            }
        </select>
    );
}

export default LocalLanguage;