import Button from 'Components/Button';
import React, { useEffect, useState } from 'react';
import './DictionaryPopup.scss';
import { useTranslation } from 'react-i18next';
import useSetLocalYupService from 'Utils/useSetLocalYupService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TranslateRepo from 'Repositories/TranslateRepo';
import { ITranslateRequest, ITranslateWordResult } from 'DTOs/ITranslate';
import { MAX_DICT_WORD_LENGTH } from 'Configs/App';
import Speech from 'Components/Audio/Speech';
import { useSelector } from 'react-redux';

interface DictionaryPopupProps {
    handleToggleDictionaryButton: () => Promise<void>;
}


const DictionaryPopup: React.FC<DictionaryPopupProps> = ({ handleToggleDictionaryButton }) => {
    const [isLocalToLearnLanguage, setIsLocalToLearnLanguage] = useState<boolean>(false);
    const [translateResult, setTranslateResult] = useState<ITranslateWordResult[] | null>(null);
    const [translateRequest, setTranslateRequest] = useState<string>("");

    const { t } = useTranslation();
    const lang = useSelector((state: any) => state.lang);

    useSetLocalYupService();
    const schema = yup.object().shape({
        content: yup.string().required().max(20).label(t('main.message')),
        fromLanguage: yup.string().required().max(20).label(t('main.message')),
        toLanguage: yup.string().required().max(20).label(t('main.message')),
    });

    const { register: formValue, handleSubmit, watch, setValue } = useForm<ITranslateRequest>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (isLocalToLearnLanguage) {
            setValue('fromLanguage', lang.localLanguage.code);
            setValue('toLanguage', lang.learnLanguage.code);
        } else {
            setValue('fromLanguage', lang.learnLanguage.code);
            setValue('toLanguage', lang.localLanguage.code);
        }

    }, [isLocalToLearnLanguage, setValue, lang]);

    const messageValue = watch('content');

    useEffect(() => {
        if (messageValue && messageValue.length > MAX_DICT_WORD_LENGTH) {
            setValue('content', messageValue.slice(0, MAX_DICT_WORD_LENGTH));
        }
    }, [messageValue, setValue]);

    const onSubmit = async (params: ITranslateRequest) => {
        const res = await TranslateRepo.translateWord(params);
        setTranslateRequest(params.content);
        if (res) {
            setTranslateResult(res);
        }
    }

    const localToLearnLanguageDict = async () => {
        setIsLocalToLearnLanguage(true);
    }
    const learnToLocalLanguageDict = async () => {
        setIsLocalToLearnLanguage(false);
    }

    const handleTranslateButton = async () => {
        await handleSubmit(onSubmit)();
    };

    return (
        <section id="dictionary_popup" >
            <div className="dictionary_container normal_bg" >
                <Button className="close_button close_icon_button" onClick={handleToggleDictionaryButton} text="" />
                <h4>{t('main.dictionary')}</h4>
                <div className='dictionary_list'>
                    <Button
                        className={!isLocalToLearnLanguage ? 'active' : ''}
                        text={lang.learnLanguage.name + " - " + lang.localLanguage.name}
                        onClick={learnToLocalLanguageDict}
                    />
                    <Button
                        className={isLocalToLearnLanguage ? 'active' : ''}
                        text={lang.localLanguage.name + " - " + lang.learnLanguage.name}
                        onClick={localToLearnLanguageDict}
                    />
                </div>
                <form className="translate_question">
                    <label htmlFor="translate_word" >{t('main.word')}</label>
                    <input id="translate_word" placeholder={t('main.fill_search_word')} {...formValue("content")} />
                    <Button className='search_icon' onClick={handleTranslateButton} text="" />
                </form>
                {
                    translateResult != null && translateResult.length > 0 &&
                    <div className="translate_result">
                        <Speech message={translateRequest} />
                        <div className="translate_content">
                            <p className="translate_source_content_title">{translateRequest}</p>
                            {
                                translateResult.map((item, index) => (
                                    <p key={index} className="translate_content_title"><span>({item.posTag})</span> {item.displayTarget}</p>
                                ))
                            }
                        </div>
                    </div>
                }
                {
                    translateResult != null && translateResult.length === 0 &&
                    <div className="translate_result">
                        <Speech message={translateRequest} />
                        <div className="translate_content">
                            <p className="translate_source_content_title">{translateRequest}</p>
                            <p>{t('main.noTranslateResult')}</p>
                        </div>
                    </div>
                }
            </div>

        </section>
    );

}
export default DictionaryPopup;