import React, { ReactNode } from 'react';
import './AuthSkeleton.scss'

interface IAuthSkeletonProps {
    children: ReactNode;
}

const AuthSkeleton: React.FC<IAuthSkeletonProps> = ({ children }) => {
    return (
        <div className='auth_board'>
            <div className="auth_box container">
                {children}
            </div>
        </div>
    );
}

export default AuthSkeleton;